import { AllocatedRole } from './userPermission.class';

export class User {
  id!: string;
  firstName!: string;
  lastName!: string;
  displayName!: string;
  userPrincipalName!: string;
  mobilePhone!: string;
  email!: string;
  role!: string;
  apiToken!: string;
  roleAllocations!: AllocatedRole[];
  identities!: Identity[];

  public constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}

export class Identity {
  signInType!: string;
  issuer!: string;
  issuerAssignedId!: string;
}
