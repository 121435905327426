import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { environment } from '@environments/environment';
import { setUserInformation } from '@store/currentUser/currentUser.actions';
import { User } from '@models/user/user.class';
import { RoleAllocations } from '@models/user/userPermission.class';

@Injectable({
  providedIn: 'root',
})
export class LoginService implements OnDestroy {
  claims: Claim[] = [];
  isUserLoggedIn$ = new BehaviorSubject<boolean>(false);
  private readonly _destroying$ = new Subject<void>();
  private uri: string = `${environment.routes.apiEndpoint}/authorisation/roles`;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private http: HttpClient,
    private store: Store
  ) {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.isUserLoggedIn$.next(this.authService.instance.getAllAccounts().length > 0);
        if (this.isUserLoggedIn$.value) {
          // Handle user login
          this.getUserInfo(this.authService.instance.getAllAccounts()[0]?.idTokenClaims);
          this.isUserLoggedIn$.next(true);
        }
      });
  }

  /**
   * Creates user object in store from claims
   * @param claims containting user information
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getUserInfo(claims: any) {
    // Get user permissions from auth service
    this.http
      .get<RoleAllocations>(`${this.uri}/allocations/${claims?.sub}/frontend`)
      .subscribe((allocations) => {
        // Map claims and permissions to user object
        const user = new User({
          id: claims.sub,
          firstName: claims.given_name,
          lastName: claims.family_name,
          email: claims.email,
          roleAllocations: allocations.roleAllocations
        });
        this.store.dispatch(setUserInformation({ user }));
      });
  }

  /**
   * Redirects to login page
   */
  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest).pipe(takeUntil(this._destroying$)).subscribe();
    } else {
      this.authService.loginRedirect().pipe(takeUntil(this._destroying$)).subscribe();
    }
  }

  /**
   * Redirects to logout page
   */
  logout(): void {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.routes.baseUrl,
    });
    this.isUserLoggedIn$.next(false);
  }

  getLoginStatus(): Observable<boolean> {
    return this.isUserLoggedIn$.asObservable();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

export class Claim {
  id?: number;
  claim?: string;
  values?: string[];
}
