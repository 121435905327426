import { createAction, props } from '@ngrx/store';
import { User } from '@models/user/user.class';
import { AllocatedRole } from '@models/user/userPermission.class';

export const setUserName = createAction(
  '[User Component] UserName',
  props<{ firstName: string; lastName: string }>()
);

export const setUserAPIToken = createAction(
  '[User Component] APIToken',
  props<{ token: string }>()
);

export const setUserPermissions = createAction(
  '[User Component] Permissions',
  props<{ roleAllocations: AllocatedRole[] }>()
);

export const setUserInformation = createAction(
  '[User Component] AllInformation',
  props<{ user: User }>()
);
